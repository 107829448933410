import Vue from "vue";
import SpinnerMixin from "@/mixins/spinner";
import { addDays } from "@/helpers/dateTimeHelper";
export default Vue.extend({
    mixins: [SpinnerMixin],
    data() {
        return {
            startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1, 3),
            endDate: new Date(),
            validationOptions: {
                rules: {
                    startDate: {
                        required: true
                    },
                    endDate: {
                        required: true
                    }
                }
            }
        };
    },
    methods: {
        search() {
            const startDate = new Date(this.startDate.setHours(0, 0, 0, 0));
            const endDate = new Date(addDays(this.endDate, 1)
                .setHours(0, 0, 0, 0));
            this.$emit("search", {
                startDate: startDate,
                endDate: endDate
            });
        }
    }
});
