import Vue from "vue";
import TitleMixin from "@/mixins/title";
import SpinnerMixin from "@/mixins/spinner";
import SearchToolbar from "@/components/Templates/Statistics/Customers/SearchToolbar.vue";
import CustomersAcquisitionChannelsStatistics from "@/components/Charts/CustomersAcquisitionChannelsStatistics.vue";
import { ButtonPlugin } from "@syncfusion/ej2-vue-buttons";
import CustomersTimeSlotsStatistics from "@/components/Charts/CustomersTimeSlotsStatistics.vue";
Vue.use(ButtonPlugin);
export default Vue.extend({
    components: {
        "search-toolbar": SearchToolbar,
        "customers-acquisition-channels-statistics": CustomersAcquisitionChannelsStatistics,
        "customers-time-slots-statistics": CustomersTimeSlotsStatistics
    },
    mixins: [TitleMixin, SpinnerMixin],
    data() {
        return {
            title: this.$t("statistics.customers"),
            chartRefs: [
                "customersAcquisitionChannelsStatistics",
                "customersTimeSlotsStatistics"
            ],
            updateChartsData: null,
            granularity: 3
        };
    },
    methods: {
        async updateCharts(params) {
            params = params || this.updateChartsData;
            this.updateChartsData = params;
            this.showSpinner();
            try {
                for (const p in this.chartRefs) {
                    await this.$refs[this.chartRefs[p]].loadData(params.startDate, params.endDate);
                }
                this.hideSpinner();
            }
            catch (errors) {
                this.hideSpinner();
                this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                    error: errors[0].message
                }));
            }
        }
    }
});
